import { useContext } from 'react';
import { Button } from "antd";
import { AuthContext } from '../AuthContext';

const LogoutButton = () => {
  const { deleteAuthToken } = useContext(AuthContext)

  return (
    <Button onClick={deleteAuthToken}>Logout</Button>
  )
}

export { LogoutButton }
