import { useState, useEffect, useContext } from 'react';
import { Card, Statistic, Skeleton } from "antd"
import { DotChartOutlined } from '@ant-design/icons';
import axios from "axios"
import { AuthContext } from '../AuthContext';

const GoldRateStatistic = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [mcxRate, setMCXRate] = useState(0);
  const { authToken } = useContext(AuthContext);

  async function fetchRates() {
    const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
    try {
      const rateData = await axios.get(baseUrl + "/quotes/GOLD23DECFUT", {
        headers: { Authorization: "Bearer " + authToken }
      })
      console.log(rateData.data.rate)
      setMCXRate(rateData.data.rate)
      if (isFetching) {
        setIsFetching(false)
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      fetchRates();
    }, 5000)
    return () => clearInterval(interval)
  })

  if (!isFetching) {
    return (<Card bordered={false}>
      <Statistic
        title="Current Gold Rate"
        value={mcxRate}
        precision={2}
        prefix='₹'
      />
    </Card>)
  } else {
    return (
      <Skeleton.Node active={isFetching}>
        <DotChartOutlined style={{ fontSize: 40, color: '#bfbfbf' }} />
      </Skeleton.Node>
    )
  }
}

export default GoldRateStatistic;
