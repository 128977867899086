import { DASHBOARD_TYPE } from "../config/config"
import { ROLES } from "../constants/user";
import { LoginData } from "../types/users";
import apiClient from "./apiClient"

const URLs = {
    [ROLES.ADMIN]: {
        "AUTHENTICATE": "/users/authenticate"
    },
    [ROLES.TRADER]: {
        "AUTHENTICATE": "/authenticate/traders"
    }
}

const authenticate = async (loginData: LoginData) => {
    return apiClient.post(`${URLs[`${DASHBOARD_TYPE}`]["AUTHENTICATE"]}`, {
        email: loginData.email,
        password: loginData.password
    })
}

export {
    authenticate
}
