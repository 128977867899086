import React from 'react';
import { Layout } from 'antd';
import { FootBar } from './FootBar';
import { NavBar } from './NavBar';

const { Content, } = Layout;

interface Props {
  children: React.JSX.Element[] | React.JSX.Element
}

const width = window.innerWidth < 750 ? '1em' : '2em'

const LayoutContainer: React.FC<Props> = (props: Props): React.JSX.Element =>
  <Layout>
    <Layout>
      <NavBar />
      <Content style={{ margin: width }}>
        {props.children}
      </Content>
      <FootBar />
    </Layout>
  </Layout>

export { LayoutContainer };
