const ORDER_CREATED = 'orderCreated'
const ORDER_ACCEPTED = 'orderAccepted'
const ORDER_READY_FOR_DISPATCH = "orderReadyForDispatch"
const PAID_BY_CUSTOMER = "paidByCustomer"
const PAYMENT_ACCEPTED = "paymentAccepted"
const PAID_TO_TRADER = "paidToTrader"
const PAYMENT_REFUNDED = "paymentRefunded"
const TRADER_REJECTED = "traderRejected"
const ADMIN_DELETED = "adminDeleted"

export {
    ORDER_CREATED,
    ORDER_ACCEPTED,
    ORDER_READY_FOR_DISPATCH,
    PAID_BY_CUSTOMER,
    PAYMENT_ACCEPTED,
    PAID_TO_TRADER,
    PAYMENT_REFUNDED,
    TRADER_REJECTED,
    ADMIN_DELETED,
}
