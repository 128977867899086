import { HttpStatusCode } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { AuthContext } from './AuthContext';
import { Home } from './Pages/Home';
import { LoginForm } from './components/loginForm';
import { Spin } from 'antd';
import styles from './styles'
import { getOrders } from './api/orders';

const isUserAuthenticated = async () => {
  const resp = await getOrders()
  return resp.status !== HttpStatusCode.Unauthorized
}

const App = () => {
  const { authToken, setAuthToken, deleteAuthToken } = useContext(AuthContext)
  const [isAuthenticating, setIsAuthenticating] = useState(true)

  useEffect(() => {
    const checkAuthToken = async () => {
      try {
        setIsAuthenticating(true)
        if (!localStorage.getItem('jwtToken')) {
          setIsAuthenticating(false)
          return
        }
        const isValidToken = await isUserAuthenticated()
        if (!isValidToken) {
          toast.error("Please login again")
          deleteAuthToken()
        } else {
          setAuthToken(localStorage.getItem('jwtToken'));
        }
        setIsAuthenticating(false)
      } catch (error) {
        toast.error("Something went wrong, please try logging in again")
        deleteAuthToken()
        setIsAuthenticating(false)
      }
    }
    checkAuthToken();
  }, [deleteAuthToken, setAuthToken])

  if (isAuthenticating)
    return <div style={{ ...styles.centerFlex, ...styles.viewportHeightAndWidth }}>
      <Spin />
    </div>

  return (
    <div className="App">
      {authToken
        ? <Home />
        : <LoginForm />
      }
      <ToastContainer />
    </div>
  )
}

export default App
