import { useContext } from 'react'
import { Button, Form, Input } from "antd"
import { AuthContext } from '../AuthContext'
import { authenticate } from '../api/user'
import { LoginData } from '../types/users'
import { HttpStatusCode } from 'axios'
import { toast } from 'react-toastify'

const LoginForm = () => {
  const { setAuthToken } = useContext(AuthContext)

  const onLogin = async (token: string, fullName: string) => {
    setAuthToken(token)
    localStorage.setItem('name', fullName)
  }

  const onFinish = async (loginData: LoginData) => {
    try {
      const response = await authenticate(loginData)
      if (response.status === HttpStatusCode.Ok) {
        toast.success("Logged in succesfully!")
        onLogin(response?.data?.jwtToken, response?.data?.name)
      }

    } catch (err: any) {
      if (err.response.status === HttpStatusCode.Unauthorized) {
        toast.error("Wrong login email and password")
      }
      console.error('Failed to login with error: ', err)
    }
  }

  return (
    <Form
      className="loginForm"
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      onFinish={onFinish}
    >
      <Form.Item label="email" name="email" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label="password" name="password" rules={[{ required: true }]}>
        <Input.Password />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit"> Submit </Button>
      </Form.Item>
    </Form>
  )
}

export { LoginForm };
