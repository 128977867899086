import { Descriptions, Skeleton, Space, Table, Tabs, TabsProps } from "antd"
import { useEffect, useState } from "react"
import { getOrders } from "../api/orders"
import OrderActions from "./OrderActions"
import { ORDER_ACCEPTED, ORDER_READY_FOR_DISPATCH } from "../constants/orderStatuses"

const buildItemChildren = (orders: any, filterKey: string, tableColumns: any) => {
  return <Table
    dataSource={orders.filter(
      (order: any) =>
        order?.item?.currentStatus === filterKey
        || order?.orderStatus === filterKey
    )}
    columns={tableColumns} />
}

const getTabItems = (orders: any, tableColumns: any,): TabsProps['items'] => {
  return [
    { key: '1', label: 'Confirmed Orders', children: buildItemChildren(orders, ORDER_ACCEPTED, tableColumns) },
    { key: '2', label: 'Ready to Dispatch', children: buildItemChildren(orders, ORDER_READY_FOR_DISPATCH, tableColumns) },
    { key: '3', label: 'Dispatched', children: buildItemChildren(orders, 'ongoing', tableColumns) },
    { key: '4', label: 'Delivered', children: buildItemChildren(orders, 'completed', tableColumns) },
    { key: '5', label: 'All Orders', children: <Table dataSource={orders} columns={tableColumns} /> }
  ]
}


const orderDetails = (order: any) => {
  const orderId = order?.orderId
  const quantity = order?.item?.quantity
  const bullionAmount = order?.item?.bullionAmount
  const amount = order?.amount
  const paymentStatus = order?.item?.currentStatus
  const createdAt = order?.created_at

  return [
    { key: '1', label: 'OrderId', children: orderId },
    { key: '2', label: 'Quantity', children: `${quantity} gm` },
    { key: '3', label: 'Bullion Amount', children: `₹ ${bullionAmount}` },
    { key: '4', label: 'Amount', children: `₹ ${amount}` },
    { key: '3', label: 'Payment Status', children: paymentStatus },
    { key: '4', label: 'Order Created At', children: createdAt }
  ]
}

const OrderCards = (orders: any, handleFetchingOrders: () => void) => {
  return (
    <div>{orders.orders.map((order: any) => {
      const orderDeets = orderDetails(order)
      return (
        <div style={{ marginTop: '2em' }}>
          <Space direction="vertical" size="middle">
            <Descriptions
              bordered={true}
              title="Order Info"
              column={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4 }}
              items={orderDeets} />
            <Descriptions.Item>
              <OrderActions order={order} handleFetchingOrders={handleFetchingOrders} />
            </Descriptions.Item>
          </Space>
        </div>)
    })}
    </div>
  )
}

function OrdersTable() {
  const [isFetching, setIsFetching] = useState(true)
  const [orders, setOrders] = useState([])

  const handleFetchingOrders = async () => {
    const response = await getOrders()
    setOrders(response.data)
    setIsFetching(false)
  }

  useEffect(() => {
    (async () => {
      await handleFetchingOrders()
    })()

  }, [])

  const width = window.innerWidth;

  const columns = [
    {
      title: 'OrderID',
      dataIndex: 'orderId',
      key: 'orderId'
    }, {
      title: 'Quantity',
      dataIndex: ['item', 'quantity'],
      key: 'Quantity'
    }, {
      title: 'Bullion Amount',
      dataIndex: ['item', 'bullionAmount'],
      key: 'bullionAmount'
    }, {
      title: 'Order Created At',
      dataIndex: 'created_at',
      key: 'createAt'
    }, {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount'
    }, {
      title: 'Payment Status',
      dataIndex: ['item', 'currentStatus'],
      key: 'status'
    },
    {
      title: 'Actions',
      dataIndex: ['item', 'currentStatus'],
      width: "20%",
      key: 'key',
      render: (text: String, record: any) => {
        return <OrderActions order={record} handleFetchingOrders={handleFetchingOrders} />
      }
    },
  ]

  if (isFetching) {
    return <Skeleton />
  } else if (width < 750) {
    return <OrderCards orders={orders} handleFetchingOrders={handleFetchingOrders} />
  } else {
    return (
      <div style={{ marginTop: "2%" }}>
        <Tabs
          size="large"
          centered
          type="card"
          defaultActiveKey="1"
          items={getTabItems(orders, columns)} />
      </div>
    )
  }
}

export default OrdersTable;
