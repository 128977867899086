import { useContext } from 'react';
import { Layout } from "antd";
import { AuthContext } from '../AuthContext';

const { Footer } = Layout;

const FootBar = () => {
  const { authToken } = useContext(AuthContext);
  if (authToken) {
    return (
      <Footer style={{ textAlign: 'center' }}>
      </Footer>
    )
  } else {
    return (
      <Footer style={{ textAlign: 'center' }} />
    )
  }
}

export { FootBar }
