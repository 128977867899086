import { Button, Flex, Spin } from "antd"
import { useState } from "react"
import { toast } from "react-toastify"
import { patchOrderStatus } from "../api/orders"
import { DASHBOARD_TYPE } from "../config/config"
import statusTransitions from "../config/statusTransitions"

const renderActions = (
    transitions: any,
    orderStatus: string,
    handleStatusChange: (newStatus: String) => void
) => {
    return <Flex gap={"small"} wrap="wrap">
        {transitions[orderStatus].map((action: any, index: number) => {
            return <Button
                key={index}
                type={action["type"]}
                danger={action["danger"]}
                size="small"
                onClick={() => handleStatusChange(action["value"])}>
                {action["label"]}
            </Button>
        })}
    </Flex>
}

interface OrderActionsProps {
    order: any
    handleFetchingOrders: () => void
}

const OrderActions = (props: OrderActionsProps) => {
    const [isPatching, setIsPatching] = useState(false)
    const { order, handleFetchingOrders } = props

    const handleStatusChange = async (newStatus: String) => {
        setIsPatching(true)
        const resp = await patchOrderStatus(order.orderId, newStatus)
        if (resp.status === 200 || resp.status === 201) {
            toast.success("Status succesfully changed!")
            await handleFetchingOrders()
            setIsPatching(false)
        } else {
            toast.error("Failed to update status")
            setIsPatching(false)
        }
    }

    if (isPatching) {
        return <Spin />
    }

    return renderActions(
        statusTransitions[`${DASHBOARD_TYPE}`],
        order?.item?.currentStatus,
        handleStatusChange
    )
}

export default OrderActions
