import axios from 'axios'

const SUBSCRIPTION_URL = '/api/v1/notifications/subscribe'
const UNSUBSCRIBE_URL = '/api/v1/notifications/unsubscribe'
// TODO: Handle getting SERVER_URL from env
const SERVER_URL = 'http://localhost:4001'

const saveSubscription = async (pushSubscription: PushSubscription, token: String) => {
  const url = SERVER_URL + SUBSCRIPTION_URL;
  return axios.post(url,
    JSON.stringify(pushSubscription),
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    });
}

const deleteSubscription = async (pushSubscription: PushSubscription, token: String) => {
  const url = SERVER_URL + UNSUBSCRIBE_URL;
  return axios.post(url,
    JSON.stringify(pushSubscription),
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    });
}

export { saveSubscription, deleteSubscription }
