export const SERVICE_WORKER_URI = './service-worker.js'

export async function register() {
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    try {
      const serviceWorkerRegistration = await navigator.serviceWorker.register(SERVICE_WORKER_URI);
      console.log('Service worker was registered', serviceWorkerRegistration)
    } catch (err) {
      console.error('An error occurred while registering the service worker.', err);
    }
  } else {
    console.error('Browser does not support service workers or push messages.');
  }
}
