import { Flex, Layout, Menu } from 'antd';
import logo from '../assets/gordian-logo.png';
import { LogoutButton } from '../components/logoutButton';
import { useContext } from 'react';
import { AuthContext } from '../AuthContext';
import { DASHBOARD_TYPE } from '../config/config';
import { ROLES } from '../constants/user';

const { Header } = Layout;

const NavBar = () => {
  const { authToken } = useContext(AuthContext)

  return <Header style={{
    position: 'sticky',
    top: 0,
    zIndex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '2em',
    background: 'black',
  }}>
    <div>
      <img style={{ filter: 'invert(100%)' }} width={50} src={logo} alt='Gordian Logo' />
    </div>
    <div style={{ color: 'white', fontSize: 28, fontWeight: 'bold' }} >
      {DASHBOARD_TYPE === ROLES.ADMIN
        ? "Admin Dashboard"
        : localStorage.getItem("name") || "Trader Dashboard"}
    </div>
    <Flex gap={10}>
      {!!authToken && <>
        <LogoutButton />
      </>}
      <Menu
        theme="dark"
        mode="horizontal"
      />
    </Flex>
  </Header >
}
export { NavBar };
