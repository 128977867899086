import React, { useState, createContext } from 'react';

const deleteAuthTokenFromLocalStore = () => localStorage.removeItem('jwtToken')
const setAuthTokenInLocalStore = (token: string | null) => {
  if (token) {
    localStorage.setItem('jwtToken', token)
  } else {
    localStorage.removeItem('jwtToken')
  }
}

interface AuthContextInterface {
  // user: any
  authToken: string | null;
  setAuthToken: (authToken: string | null) => void
  deleteAuthToken: () => void
}

const AuthContext = createContext<AuthContextInterface>({
  authToken: null,
  // user: null,
  setAuthToken: setAuthTokenInLocalStore,
  deleteAuthToken: deleteAuthTokenFromLocalStore,
})

interface AuthContextProviderProps {
  children: React.JSX.Element | React.JSX.Element[]
}

const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const [currentToken, setCurrentToken] = useState<string | null>(null);

  return (
    <AuthContext.Provider value={{
      // user: user,
      authToken: currentToken,
      setAuthToken: (token: string | null) => {
        setCurrentToken(token);
        setAuthTokenInLocalStore(token);
      },
      deleteAuthToken: () => {
        setCurrentToken(null);
        deleteAuthTokenFromLocalStore();
      }
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthContextProvider };
