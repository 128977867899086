import { DASHBOARD_TYPE } from "../config/config"
import { ROLES } from "../constants/user"
import { getJwtToken } from "../utils"
import apiClient from "./apiClient"

const URLs = {
    [ROLES.ADMIN]: {
        "GET_ORDERS": "/bullion/orders",
        "PATCH_ORDER": "/admin/orders"
    },
    [ROLES.TRADER]: {
        "GET_ORDERS": "/bullion/orders/traders",
        "PATCH_ORDER": "/trader/orders"
    }
}

const getOrders = async () =>
    await apiClient.get(`${URLs[`${DASHBOARD_TYPE}`]["GET_ORDERS"]}?limit=12`, {
        headers: { "Authorization": "Bearer " + getJwtToken() }
    })

const patchOrderStatus = async (orderId: String, newStatus: String) =>
    await apiClient.patch(`${URLs[`${DASHBOARD_TYPE}`]["PATCH_ORDER"]}/${orderId}`,
        { item: { status: newStatus } },
        { headers: { "Authorization": "Bearer " + getJwtToken() } }
    )


export {
    getOrders,
    patchOrderStatus,
}
