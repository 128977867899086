import OrdersTable from '../../components/ordersTable';
import GoldRateStatistic from '../../components/GoldRateStatistic';
import { subscribe } from '../../notification';
import './Home.css';
import { useContext, useEffect } from 'react';
import { AuthContext } from '../../AuthContext';

function Home() {
  const { authToken } = useContext(AuthContext)

  useEffect(() => {
    subscribe(`${authToken}`)
  }, [authToken])

  return (
    <div className='Home'>
      <GoldRateStatistic />
      <OrdersTable />
    </div>
  )
}

export { Home };
