import { ROLES } from "../constants/user";

const statusTransitions = {
    [ROLES.ADMIN]: {
        "orderCreated": [
            { label: "Accept", value: "orderAccepted", type: "primary" },
            { label: "Decline", value: "adminDeleted", danger: true }
        ],
        "orderAccepted": [
            { label: "Paid By Customer", value: "paidByCustomer" },
            { label: "Confirm Payment", value: "paymentAccepted" },
            { label: "Paid to Trader", value: "paidToTrader" },
            { label: "Decline", value: "adminDeleted", danger: true }
        ],
        "paidByCustomer": [
            { label: "Accept Payment", value: "paymentAccepted" },
            { label: "Paid to Trader", value: "paidToTrader" },
            { label: "Decline", value: "adminDeleted", danger: true }
        ],
        "paymentAccepted": [
            { label: "Paid to Trader", value: "paidToTrader", type: "primary" }
        ],
        "paidToTrader": [],
        "adminDeleted": [],
        "traderRejected": [],
        "paymentRefunded": [],
        "orderReadyForDispatch": []
    },
    [ROLES.TRADER]: {
        "orderCreated": [
            { label: "Accept", value: "orderAccepted", type: "primary" },
            { label: "Decline", value: "traderRejected", danger: true }
        ],
        "paidToTrader": [
            { label: "Mark as Ready", value: "orderReadyForDispatch", type: "primary" }
        ],
        "orderAccepted": [],
        "paymentAccepted": [],
        "paidByCustomer": [],
        "adminDeleted": [],
        "traderRejected": [],
        "paymentRefunded": [],
        "orderReadyForDispatch": [],

    }
}

export default statusTransitions
