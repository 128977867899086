import axios from 'axios';
import { BASE_URL } from '../config/config';

const client = axios.create({
    baseURL: BASE_URL,
    timeout: 35000,
    headers: { 'Content-Type': 'application/json' },
});

export default client
