interface IStyle {
    [index: string]: React.CSSProperties
}

const styles: IStyle = {
    centerFlex: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
    viewportHeightAndWidth: { height: '100vh', width: '100vw' }
}

export default styles
