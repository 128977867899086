import { SERVICE_WORKER_URI } from './serviceWorkerRegistration';
import * as NotificationAPI from './api/notifications';
import { toast } from 'react-toastify';

function urlBase64ToUint8Array(base64String: string): Uint8Array {
  let padding = '='.repeat((4 - base64String.length % 4) % 4);
  let base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  let rawData = window.atob(base64);
  let outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

async function subscribe(userToken: string) {
  const result = await Notification.requestPermission();
  if (result !== 'granted') {
    toast.warn("Please give permissions to send order notifications")
    console.error('User hasn\'t granted notification permission. Cannot proceed with subscription')
    return
  }

  try {
    const swReg = await navigator.serviceWorker.getRegistration(SERVICE_WORKER_URI);
    const vapidPublicKey = process.env.REACT_APP_VAPID_PUBLIC_KEY || '';
    const subscribeOptions = {
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(vapidPublicKey),
    };
    const pushSubscription = await swReg?.pushManager.subscribe(subscribeOptions);
    if (pushSubscription) {
      await NotificationAPI.saveSubscription(pushSubscription, userToken)
    }
  } catch (err) {
    console.error('Failed to create subscription with error', err);
  }
}

async function unsubscribe(_userToken: String) {
  console.log('Not yet implemented')
}

export { subscribe, unsubscribe }
